// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-escreva-para-lj-pt-js": () => import("./../../../src/pages/escreva-para-LJ.pt.js" /* webpackChunkName: "component---src-pages-escreva-para-lj-pt-js" */),
  "component---src-pages-escribe-a-lj-es-js": () => import("./../../../src/pages/escribe-a-LJ.es.js" /* webpackChunkName: "component---src-pages-escribe-a-lj-es-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-it-js": () => import("./../../../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pt-js": () => import("./../../../src/pages/index.pt.js" /* webpackChunkName: "component---src-pages-index-pt-js" */),
  "component---src-pages-informazioni-legali-it-js": () => import("./../../../src/pages/informazioni-legali.it.js" /* webpackChunkName: "component---src-pages-informazioni-legali-it-js" */),
  "component---src-pages-legal-notice-en-js": () => import("./../../../src/pages/legal-notice.en.js" /* webpackChunkName: "component---src-pages-legal-notice-en-js" */),
  "component---src-pages-message-lj-en-js": () => import("./../../../src/pages/message-LJ.en.js" /* webpackChunkName: "component---src-pages-message-lj-en-js" */),
  "component---src-pages-nota-legal-es-js": () => import("./../../../src/pages/nota-legal.es.js" /* webpackChunkName: "component---src-pages-nota-legal-es-js" */),
  "component---src-pages-representante-legal-pt-js": () => import("./../../../src/pages/representante-legal.pt.js" /* webpackChunkName: "component---src-pages-representante-legal-pt-js" */),
  "component---src-pages-scrivi-a-lj-it-js": () => import("./../../../src/pages/scrivi-a-LJ.it.js" /* webpackChunkName: "component---src-pages-scrivi-a-lj-it-js" */),
  "component---src-templates-happiness-corner-template-js": () => import("./../../../src/templates/happinessCornerTemplate.js" /* webpackChunkName: "component---src-templates-happiness-corner-template-js" */),
  "component---src-templates-message-template-js": () => import("./../../../src/templates/messageTemplate.js" /* webpackChunkName: "component---src-templates-message-template-js" */),
  "component---src-templates-paginated-messages-template-js": () => import("./../../../src/templates/paginatedMessagesTemplate.js" /* webpackChunkName: "component---src-templates-paginated-messages-template-js" */)
}

